<template>
   <div class="modal fade" id="modalVerComprovante" tabindex="-1" aria-labelledby="modalVerComprovanteLabel" aria-hidden="true">
      <div class="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
         <div class="modal-content border-0 bg-transparent">
            <div class="modal-body p-0">
               <div class="card w-max-content mx-auto">
                  <div class="card-body p-0">
                     <impressaoSangria id="sangria" :sangria="comprovante" v-if="tipo == 'SANGRIA'" />
                     <impressaoVenda id="venda" :venda="comprovante" v-else-if="tipo == 'VENDA_NFCE'" />
                     <impressaoEntradaSaida id="entradaSaida" v-else-if="comprovante != null" titulo="Entrada/Saída" 
                        :data="comprovante.data == null ? null : String(comprovante.data.dayOfMonth).padStart(2, '0') +'/'+ String(comprovante.data.monthValue).padStart(2, '0') +'/'+ comprovante.data.year +' '+
                           String(comprovante.data.hour).padStart(2, '0') +':'+ String(comprovante.data.minute).padStart(2, '0')"
                        :cabecalho="{
                           'Operação': comprovante.motivo, 
                           'Loja destinatário': comprovante.nomeLoja +' / Nº '+ comprovante.numeroLoja, 
                           }" :itensKey="{'nomeProduto': 'Descrição', 'qtd': 'Qtd.', 'valor': 'Valor'}" :itens="comprovante.produtos" :total="comprovante.valorTotal" />
                  </div>
               </div>
            </div>
            <div class="modal-footer border-0 justify-content-center">
               <button type="button" class="btn btn-primary" @click="imprimir">Imprimir</button>
               <button type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="modalAnterior">{{ modalAnterior == null ? 'Fechar' : 'Voltar' }}</button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import impressaoVenda from '@/components/impressao/ComprovanteVenda.vue'
import impressaoSangria from '@/components/impressao/ComprovanteSangria.vue'
import impressaoEntradaSaida from '@/components/impressao/ComprovanteA4.vue'

export default {
   name: 'ModalComprovante',
   props: ['modalAnterior', 'tipo', 'comprovante'],
   components: {
      impressaoVenda, impressaoSangria, impressaoEntradaSaida
	},
   methods: {
      imprimir : function () {
         switch (this.tipo) {
            case 'SANGRIA':
               this.$htmlToPaper('sangria', {styles: ['/bootstrap.print.css']})
            break;
            case 'VENDA_NFCE':
               this.$htmlToPaper('venda', {styles: ['/bootstrap.print.css']})
            break;
            default:
               this.$htmlToPaper('entradaSaida', {styles: ['/comprovanteA4.css']})
            break;
         }
      }
   }
}

</script>

<style scoped>

#entradaSaida,
#sangria,
#venda {
   overflow: auto;
}

.modal-body,
.modal-body * {
   scrollbar-color: #0000 #0000 !important;
}

.modal-body::-webkit-scrollbar-thumb,
.modal-body *::-webkit-scrollbar-thumb {
   background: #0000 !important;
}

</style>