<template>
   <div class="row m-0 bg-white text-dark rounded">
      <div class="col-12 px-0 m-3" style="width: 350px;">
         <div class="row m-0 align-items-center">
            <div class="col-3 px-1 text-center">
               <img src="@/assets/images/notas/nfe.png" class="w-100" v-if="venda.fiscal != false" />
               <img src="@/assets/images/logo-icon.png" class="w-75 opacity-50" v-else />
            </div>
            <div class="col-9 px-1 text-center" style="font-size: 12px;">
               <span class="d-block text-truncate" style="font-size: 14px;">{{ String(venda.lojaNome).toUpperCase() }}</span>
               <span class="d-block text-truncate">
                  CNPJ: {{ venda.lojaCnpj == null || String(venda.lojaCnpj).trim().length == 0 ? 'Não informado' : venda.lojaCnpj }}
                  - IE: {{ venda.lojaIe == null || String(venda.lojaIe).trim().length == 0 ? 'Não informado' : venda.lojaIe }}
               </span>
               <span class="d-block text-truncate">
                  {{ venda.lojaEndereco == null || String(venda.lojaEndereco).trim().length == 0 ? 'Não informado' : venda.lojaEndereco }}
                  <br/> Telefone: {{ venda.lojaTelefone == null || String(venda.lojaTelefone).trim().length == 0 ? 'Não informado' : venda.lojaTelefone }}
               </span>
            </div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">

         <div class="row m-0 align-items-center" style="font-size: 11px;">
            <div class="col-12 px-1 text-truncate text-center" v-if="venda.fiscal != false">Documento auxiliar da nota fiscal do consumidor eletrônica</div>
            <div class="col-12 px-1 text-truncate text-center" v-else>Documento não fiscal</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">

         <div class="row m-0 align-items-center" style="font-size: 11.5px;">
            <div class="col-4 px-1 text-truncate">Código</div>
            <div class="col-8 px-0 text-truncate">Descrição</div>
            <div class="col-4 px-1 text-truncate">Quantidade</div>
            <div class="col-4 px-0 text-truncate text-end">Unitário</div>
            <div class="col-4 px-1 text-truncate text-end">Total</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">
         
         <div class="row m-0 mb-1 align-items-center" style="font-size: 11.5px;" v-for="(p, index) in venda.produtos" :key="index">
            <div class="col-4 px-1 text-truncate">{{ p.ean }}</div>
            <div class="col-8 px-0 text-truncate">{{ p.nome }}</div>
            <div class="col-4 px-1 text-truncate">{{ p.qtd }}</div>
            <div class="col-4 px-0 text-truncate text-end">{{ parseFloat(p.valorUn).toFixed(2) }}</div>
            <div class="col-4 px-1 text-truncate text-end">{{ parseFloat(p.valorTotal).toFixed(2) }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">

         <div class="row m-0 align-items-center" style="font-size: 12px;">
            <div class="col-8 px-1 text-truncate">Quantidade total de itens</div>
            <div class="col-4 px-1 text-truncate text-end">{{ parseFloat(venda.vendaQtdItens).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}</div>
            <div class="col-8 px-1 text-truncate">Valor Total</div>
            <div class="col-4 px-1 text-truncate text-end">R$ {{ parseFloat(venda.vendaTotal).toFixed(2) }}</div>
            <div class="col-8 px-1 text-truncate">Troco</div>
            <div class="col-4 px-1 text-truncate text-end">R$ {{ parseFloat(venda.pagamentos.reduce((total, b) => total + parseFloat(b.valor), 0) - parseFloat(venda.vendaTotal)).toFixed(2) }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">
         
         <div class="row m-0 align-items-center" style="font-size: 12px;">
            <div class="col-8 px-1 text-truncate">Forma de Pagamento</div>
            <div class="col-4 px-1 text-truncate text-end">Valor Pago</div>
            <div class="col-12 px-0">
               <div class="row m-0" v-for="p in venda.pagamentos" :key="p.id">
                  <div class="col-8 px-1 text-truncate">{{ p.formaPgto.replace(/_/g, " ") }}</div>
                  <div class="col-4 px-1 text-truncate text-end">R$ {{ parseFloat(p.valor).toFixed(2) }}</div>
               </div>
            </div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;">
         
         <div class="row m-0 align-items-center" v-if="venda.fiscal != false">
            <div class="col-12 px-1 text-center mb-1" style="font-size: 12px;">
               <span class="d-block text-truncate">Consulte pela chave de acesso</span>
               <span class="d-block text-truncate">http://www.fazenda.pr.gov.br/nfce/consulta</span>
               <span class="d-block text-truncate">{{ venda.vendaChave }}</span>
            </div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;" v-if="venda.fiscal != false">

         <div class="row m-0 align-items-center" v-if="venda.fiscal != false">
            <div class="col-5 px-1">
               <qrcode-vue :value="venda.vendaQrCode == null ? 'http://www.fazenda.pr.gov.br/nfce/consulta' : venda.vendaQrCode" size="130" level="H" background="#0000" renderAs="svg" class="w-100" />
            </div>
            <div class="col-7 px-1 text-center" style="font-size: 12px;">
               <span class="d-block text-truncate lh-sm my-2" style="font-size: 13px;">CONSUMIDOR:<br>{{ venda.vendaCpfCliente == null ? 'NÃO IDENTIFICADO' : venda.vendaCpfCliente }}</span>
               <span class="d-block text-truncate">Nº {{ venda.vendaNumeroNota }} - Série {{ venda.vendaSerie }}</span>
               <span class="d-block text-truncate mb-2">Emissão: {{ venda.vendaData }}</span>
               <span class="d-block text-truncate lh-sm">Protocolo de autorização:<br>{{ venda.vendaProtocolo }}</span>
            </div>
         </div>

         <hr class="bg-transparent my-2" style="border: 1px dashed #000 !important;" v-if="venda.fiscal != false">

         <div class="row m-0 align-items-center text-center" v-if="venda.fiscal != false" style="font-size: 12px;">{{ venda.observacao }}</div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'ComprovanteVenda',
	props: ['venda']
}

</script>