<template>
   <div class="card mb-0" ref="comprovante" :index="index">
      <div class="card-img text-center overflow-hidden">
         <img src="@/assets/images/notas/nfe.png" alt="icon" v-if="cupom.motivo == 'VENDA_NFCE'">
         <img src="@/assets/images/comprovante.png" alt="icon" class="p-1" v-else>
      </div>
      <div class="card-body text-center p-2">
         <h5 class="limitador-1">
            <small>R$ </small><span class="font-15">{{ cupom.valor == null ? ' - ' : parseFloat(cupom.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
         </h5>
         <p class="mb-0 d-none d-sm-block">
            <span class="limitador-1" v-if="cupom.motivo == 'SANGRIA'">
               <i class="far fa-user-check color-theme font-10 me-1"></i><span> {{ cupom.nomeFiscal == null ? 'Desconhecido' : cupom.nomeFiscal }}</span>
            </span>
            <span class="limitador-1" v-else-if="cupom.formaPagto != null">
               <i class="far fa-stream color-theme font-10 me-1"></i>
               <span> {{ String(cupom.formaPagto).toLowerCase().replace(/_/g, " ").replace("de", "") }} {{ cupom.idUsuario ? '(ID '+ cupom.idUsuario +')' : '' }}</span>
            </span>
            <span class="limitador-1" v-else-if="cupom.motivo != null">
               <i class="far fa-stream color-theme font-10 me-1"></i><span> {{ String(cupom.motivo).replace("_NFCE", "").toLowerCase() }}</span>
            </span>
         </p>
         <p class="mb-0 d-none d-lg-block" v-if="cupom.idPdv">
            <span class="limitador-1">
               <i class="far fa-desktop color-theme font-10 me-1"></i><span> Caixa {{ cupom.idPdv }}</span>
            </span>
         </p>
         <p class="limitador-1 mb-1">
            <i class="far fa-calendar color-theme font-10 me-1"></i><span> {{ cupom.data }}</span>
         </p>

         <button class="btn btn-white invert" @click="getSangria" v-if="cupom.motivo == 'SANGRIA'">
            <i class="fal font-11 me-1" :class="preview ? 'fa-plus' : 'fa-print'"></i> {{ preview ? 'Visualizar' : 'Imprimir' }}
         </button>
         <button class="btn btn-white invert" @click="getVenda" v-else-if="cupom.motivo == 'VENDA_NFCE'">
            <i class="fal font-11 me-1" :class="preview ? 'fa-plus' : 'fa-print'"></i> {{ preview ? 'Visualizar' : 'Imprimir' }}
         </button>
         <button class="btn btn-white invert" @click="getEntradaSaida" v-else>
            <i class="fal font-11 me-1" :class="preview ? 'fa-plus' : 'fa-print'"></i> {{ preview ? 'Visualizar' : 'Imprimir' }}
         </button>
      </div>

      <impressaoVenda :id="'venda'+ index" class="d-none" :venda="venda" v-if="venda != null" />
      <impressaoSangria :id="'sangria'+ index" class="d-none" :sangria="sangria" v-if="sangria != null" />
      <impressaoEntradaSaida :id="'entradaSaida'+ index" class="d-none" v-if="entradaSaida != null" titulo="Entrada/Saída"
         :data="entradaSaida.data == null ? null : String(entradaSaida.data.dayOfMonth).padStart(2, '0') +'/'+ String(entradaSaida.data.monthValue).padStart(2, '0') +'/'+ entradaSaida.data.year +' '+
            String(entradaSaida.data.hour).padStart(2, '0') +':'+ String(entradaSaida.data.minute).padStart(2, '0')"
         :cabecalho="{
            'Operação': entradaSaida.motivo, 
            'Loja destinatário': entradaSaida.nomeLoja +' / Nº '+ entradaSaida.numeroLoja, 
            }" :itensKey="{'nomeProduto': 'Descrição', 'qtd': 'Qtd.', 'valor': 'Valor'}" :itens="entradaSaida.produtos" :total="entradaSaida.valorTotal" />
   </div>
</template>

<script>

import { mapState } from 'vuex'
import impressaoVenda from '@/components/impressao/ComprovanteVenda.vue'
import impressaoSangria from '@/components/impressao/ComprovanteSangria.vue'
import impressaoEntradaSaida from '@/components/impressao/ComprovanteA4.vue'

export default {
	name: 'Comprovante',
	props: ['cupom', 'index', 'preview'],
   data: function () {
      return {
         venda: null,
         sangria: null,
         entradaSaida: null
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         tokenSessao: state => state.tokenSessao,
         urlRest: state => state.urlRest
      })
   },
   components: {
      impressaoVenda, impressaoSangria, impressaoEntradaSaida
   },
   methods: {
      getSangria : function () {
         this.$store.dispatch('alternarTelaCarregamento', true);
         this.sangria = null;

         this.$axios({
            method: 'get',
            url: this.urlRest +'resultados/getSangria',
            params: {
               id: this.cupom.id
            }
         }).then(response => {
            if (this.preview) {
               this.$emit('visualizar', {'tipo': 'SANGRIA', 'dados': response.data})
            } else {
               this.sangria = response.data;
               setTimeout(() => this.$htmlToPaper('sangria'+ this.index, {styles: ['/bootstrap.print.css']}), 250);
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar');

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false);
         });
      },
      getVenda : function () {
         this.$store.dispatch('alternarTelaCarregamento', true);
         this.venda = null;

         this.$axios({
            method: 'get',
            url: this.urlRest +'resultados/getVenda',
            params: {
               idNota: this.cupom.id
            }
         }).then(response => {
            if (this.preview) {
               this.$emit('visualizar', {'tipo': 'VENDA_NFCE', 'dados': response.data})
            } else {
               this.venda = response.data;
               setTimeout(() => this.$htmlToPaper('venda'+ this.index, {styles: ['/bootstrap.print.css']}), 250);
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar');

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false);
         });
      },
      getEntradaSaida : function () {
         this.$store.dispatch('alternarTelaCarregamento', true);
         this.venda = null;

         this.$axios({
            method: 'get',
            url: this.urlRest +'resultados/getEntradaSaida',
            params: {
               id: this.cupom.id
            }
         }).then(response => {
            if (this.preview) {
               this.$emit('visualizar', {'tipo': 'ENTRADA_SAIDA', 'dados': response.data})
            } else {
               this.entradaSaida = response.data;
               setTimeout(() => this.$htmlToPaper('entradaSaida'+ this.index, {styles: ['/comprovanteA4.css']}), 250);
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar');

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false);
         });
      }
   }
}

</script>

<style scoped>

h5 {
   font-size: 14px;
   margin-bottom: 8px;
   font-weight: 500;
}

.card[index] img {
	aspect-ratio: 1/1;
	object-fit: cover;
   max-width: 100px;
   margin: 10px 0;
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

p span {
   font-size: 12px;
   text-transform: capitalize;
}

button {
   margin-top: 10px;
   transition: all 0.5s;
   font-size: 12px;
}

button:hover {
   background-color: var(--color-theme);
   border-color: var(--color-theme);
   color: #fff;
   border-radius: 3px;
}

</style>