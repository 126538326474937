<template>
   <div class="row m-0 bg-white text-dark rounded">
      <div class="col-12 px-0 m-3" style="width: 350px; font-size: 12px;">
         <div class="row m-0 align-items-center text-center">
            <div class="col-12 px-1" style="font-size: 14px;">{{ String(sangria.lojaNome).toUpperCase() }}</div>
            <div class="col-12 px-1">
               CNPJ: {{ sangria.lojaCnpj == null || String(sangria.lojaCnpj).trim().length == 0 ? 'Não informado' : sangria.lojaCnpj }}
               - IE: {{ sangria.lojaIe == null || String(sangria.lojaIe).trim().length == 0 ? 'Não informado' : sangria.lojaIe }}
            </div>
            <div class="col-12 px-1">
               {{ sangria.lojaEndereco == null || String(sangria.lojaEndereco).trim().length == 0 ? 'Não informado' : sangria.lojaEndereco }}
               <br/> Telefone: {{ sangria.lojaTelefone == null || String(sangria.lojaTelefone).trim().length == 0 ? 'Não informado' : sangria.lojaTelefone }}
            </div>
         </div>

         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-12 px-1 text-center">Detalhes da sangria</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="col-4 px-1">Fiscal:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ sangria.fiscal }}</div>
            <div class="col-4 px-1">Operador(a):</div>
            <div class="col-8 px-1 text-end text-truncate">{{ sangria.operador }}</div>
            <div class="col-4 px-1">Data:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ sangria.data }}</div>
            <div class="col-4 px-1">Loja:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ sangria.lojaNumero }}</div>
            <div class="col-4 px-1">Caixa:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ sangria.pdv }}</div>
            <div class="col-4 px-1">Período:</div>
            <div class="col-8 px-1 text-end text-truncate">{{ sangria.periodo }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
         
         <div class="row m-0 align-items-center">
            <div class="w-100 text-center">Valor retirado: R$ {{ parseFloat(sangria.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</div>
         </div>
         
         <hr class="bg-transparent my-2" style="border: 1px dashed #000;">
      </div>
   </div>
</template>

<script>

export default {
	name: 'ComprovanteSangria',
	props: ['sangria']
}

</script>